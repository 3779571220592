const globalSnackbarModule = {
  namespaced: true,
  state: {
    show: false,
    content: null,
    type: null,
    timeout: null
  },
  getters: {
    show (state) {
      return state.show;
    },
    content (state) {
      return state.content;
    },
    type (state) {
      return state.type;
    },
    timeout (state) {
      return state.timeout;
    }
  },
  mutations: {
    displayMessage (state, message) {
      state.show = true;
      state.content = message.content;
      state.type = message.type;
      state.timeout = message.timeout || 5000;
    },
    clearMessage (state, value) {
      state.show = value || false;
      state.content = null;
      state.type = null;
      state.timeout = null;
    },
    /* eslint-disable */
    displayGlazeResponse (state, { response, type, fallbackMessage = "Something Went Wrong" }) {
      const errorMessages = [];

      const { data, status } = response;

      // Only push the most helpful error message
      // ie show a application error before you say 422
      if (data && data.full_error_messages) {
        errorMessages.push(data.full_error_messages);
      // This field is only sent in development, but this greatly helps.
      } else if (data && data.errors && data.errors.base) {
        errorMessages.push(data.errors.base.join("\n"));
      } else if (data && data.exception) {
        errorMessages.push(data.exception);
      } else if (data && data.error) {
        errorMessages.push(data.error);
      } else if (response.status && response.statusText) {
        errorMessages.push(`${response.statusText} (${response.status})`);
      }

      if ((errorMessages.length === 0 || status >= 500) && fallbackMessage) {
        errorMessages.unshift(fallbackMessage);
      }

      state.show = true;
      state.content = errorMessages.join(". ");
      state.type = type;
    }
    /* eslint-enable */
  }
};

export default globalSnackbarModule;
