/* eslint-disable */
import store from "./store/store.js";

function initGoogleAnalytics() {
  // Modified from https://stackoverflow.com/a/31374433
  const scriptTag = document.createElement('script');

  scriptTag.async = 'async';
  scriptTag.src = "https://www.googletagmanager.com/gtag/js?id=UA-49958900-7";

  scriptTag.onload = function() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push('js', new Date());
    window.dataLayer.push('config', 'UA-49958900-7');
  }

  document.body.appendChild(scriptTag);
}

initGoogleAnalytics();
