const userModule = {
  namespaced: true,
  state: {
    glazeToken: null,
    email: null,
    number: null,
    roles: null
  },
  getters: {
    email (state) {
      return state.email;
    },
    glazeToken (state) {
      return state.glazeToken;
    },
    roles (state) {
      return state.roles;
    },
    isInternalEngineer (state) {
      return (state.roles || []).includes("engineering");
    },
    isEmployee (state) {
      return (state.roles || []).includes("employee");
    }
  },
  mutations: {
    setAuthenticatedUser (state, auth) {
      state.email = auth.email;
      state.glazeToken = auth.glazeToken;
      state.number = auth.number;
      state.roles = auth.roles;
      state.firstName = auth.firstName;
      state.lastName = auth.lastName;
    },
    logout (state) {
      state.email = null;
      state.glazeToken = null;
      state.roles = null;
      state.isEmployee = false;
    }
  }
};

export default userModule;
