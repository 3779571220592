import Vue from "vue";
import router from "../setup/routing/router";
import store from "../setup/store/store.js";
import RouteView from "../pages/general/RouteView.vue";
import "Editor/theme.scss";

import "../setup/analytics.js";

import * as Sentry from "@sentry/browser";
import SentryFullStory from "@sentry/fullstory";
import * as FullStory from "@fullstory/browser";

import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "../stylesheets/frontend-stylesheets.scss";

let clientSha;

const opts = {
  theme: { disable: true }
};

Vue.use(Vuetify);

function setVueEnv (store) {
  fetch("/api/config")
    .then(req => req.json())
    .then(data => {
      store.commit("setEnv", data);

      // Grab initial code sha on boot
      fetch("/status.json")
        .then(req => req.json())
        .then(data => {
          clientSha = data.revision;
        });

      // Check for deploy of new code versions
      const newCodeCheck = function () {
        fetch("/status.json")
          .then(req => req.json())
          .then(data => {
            if (clientSha !== data.revision) {
              clientSha = data.revision;
              if (alert("We have made some changes and are refreshing your browser.")) {
                // Do nothing
              } else {
                window.location.reload();
              }
            }
          });
      };
      setInterval(function () { newCodeCheck(); }, 60000);

      FullStory.init({ orgId: "1V8KB", recordCrossDomainIFrames: true });
      Vue.prototype.$FullStory = FullStory;

      if (store.state.user) {
        FullStory.identify(store.state.user.number, {
          displayName: store.state.user.number,
          email: store.state.user.email,
          app: "designer"
        });
      }

      if (data.sentryClientDSN) {
        Sentry.init({
          dsn: data.sentryClientDSN,
          integrations: [new Sentry.Integrations.Vue({
            Vue,
            attachProps: true
          }),
          new SentryFullStory("framebridge")
        ]
        });
      }
    });
}

setVueEnv(store);

/* eslint-disable-next-line no-new */
new Vue({
  el: "#designer-app",
  router,
  store,
  vuetify: new Vuetify(opts),
  render: createElement => createElement(RouteView)
});
