import UserHelper from "../mixins/UserHelper";

export const formatSpreeUserAsConsultationUser = (selectedUser) => {
  let firstName, lastName, phone;
  if (selectedUser.bill_address) {
    firstName = selectedUser.bill_address.firstname;
    lastName = selectedUser.bill_address.lastname;
    phone = selectedUser.bill_address.phone;
  }

  return {
    email: selectedUser.email,
    id: selectedUser.id,
    number: selectedUser.number,
    orderCount: selectedUser.order_count,
    glazeToken: selectedUser.spree_api_key,
    createdAt: selectedUser.created_at,
    firstName: selectedUser.first_name || firstName,
    lastName: selectedUser.last_name || lastName,
    fullName: UserHelper.methods.userFullName(selectedUser),
    phone
  };
};
