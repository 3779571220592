import GlazeClient from "@framebridge/toolbox/apihelpers/glazeClient";

import { formatSpreeUserAsConsultationUser } from "../../../models/user.js";

/**
 * Retail customer consultation module
 *
 * Store the current user and cart being used for a retail customer consultation
 * vuex.
 */

export default {
  namespaced: true,
  state: {
    user: null,
    order: null
  },
  getters: {
    user (state) {
      return state.user;
    },
    order (state) {
      return state.order;
    }
  },
  mutations: {
    setUser (state, user) {
      state.user = { ...user };
    },
    resetUser (state) {
      state.user = null;
    },
    setOrder (state, order) {
      state.order = { ...order };
    },
    resetOrder (state) {
      state.order = null;
    }
  },
  actions: {
    async cleanUpCart ({ rootGetters }) {
      const cart = await this.dispatch("Cart/current");

      if (cart.line_items) {
        const lineItems = cart.line_items.filter(item => {
          return item.is_gift_card || item.is_in_store_product;
        });

        lineItems.forEach(async lineItem => {
          const payload = {
            orderNumber: cart.number,
            lineItemId: lineItem.id
          };
          await this.dispatch("LineItem/delete", payload);
        });
      }
    },

    async resetConsultation ({ commit, rootState, state, dispatch }) {
      const client = new GlazeClient(rootState.user.glazeToken);

      await dispatch("cleanUpCart");

      if (state.user && state.user.id) {
        client.delete(`/api/impersonators/${state.user.id}`);
      }

      commit("resetOrder");
      commit("resetUser");
      commit("frameBuilder/resetUploads", {}, { root: true });
      commit("frameBuilder/resetArtworks", {}, { root: true });
    },
    saveUser ({ commit, rootState, state }, userChanges) {
      const client = new GlazeClient(rootState.user.glazeToken);

      return client.put(`/api/users/${state.user.id}`, { user: userChanges }, { rejectOnNotOk: true }).then((response) => {
        commit("setUser", formatSpreeUserAsConsultationUser(response.data));
        return response;
      });
    }
  }
};
