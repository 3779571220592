import { createNamespacedHelpers } from "vuex";
import GlazeClient from "@framebridge/toolbox/apihelpers/glazeClient";
const { mapGetters } = createNamespacedHelpers("user");

const handleGlazeClientUnauthenticatedResponse = function ({ response }) {
  if (response.status === 401) {
    this.$store.commit("user/logout");
    this.$router.push({ name: "login" });
    console.error("Logged out user due to a 401 response");
  }
};

const mixin = {
  computed: {
    ...mapGetters(["glazeToken"]),
    GlazeClient () {
      const client = new GlazeClient(this.glazeToken, {
        interceptors: {
          response: handleGlazeClientUnauthenticatedResponse.bind(this)
        }
      });
      return client;
    }
  },
  data () {
    return {
      GlazeClientWithoutAuth: new GlazeClient()
    };
  }
};
export default mixin;
