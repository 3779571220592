const getters = {
  glazeUrl (state) {
    return state.env.glazeUrl;
  },
  retailAtgToken (state) {
    return state.env.retailAtgToken;
  }
};

export default getters;
