import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import store from  "./../store/store";

Vue.use(VueRouter);

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  if (!store.state.user.glazeToken && to.name !== "login") {
    // reroute to login
    next({
      name: "login",
      params: {
        redirect: to
      }
    });
  } else {
    next();
  }
});

export default router;
