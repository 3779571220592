/**
 * Retail customer consultation module
 *
 * Store the special instruction type (from_retail) id in vuex.
 *
 */

 const specialInstructionTypeModule = {
  namespaced: true,
  state: {
    id: null
  },
  mutations: {
    id (state, id) {
      state.id = id;
    }
  }
};

export default specialInstructionTypeModule;
