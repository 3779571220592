import GlazeClient from "./GlazeClient";

const mixin = {
  mixins: [
    GlazeClient
  ],
  methods: {
    userFullName (user) {
      if (user && user.bill_address && user.bill_address.full_name) {
        return user.bill_address.full_name;
      } else if (user && (user.first_name || user.last_name)) {
        return [user.first_name, user.last_name].join(" ");
      } else {
        return "Unknown";
      }
    },
    userPhoneNumber (user) {
      if (user && user.bill_address && user.bill_address.phone) {
        return user.bill_address.phone;
      } else {
        return "Unknown";
      }
    },
    userYearCreated (user) {
      if (user && user.created_at) {
        return user.created_at.substring(0, 4);
      } else {
        return "Unknown";
      }
    },
    userEmail (user) {
      if (user && user.email) {
        return user.email;
      } else {
        return "Unknown";
      }
    },
    searchUsers (searchByField, searchByText, page, limit) {
      const allowedParams = [
        "email_cont",
        "email_eq",
        "first_name_start",
        "last_name_start"
      ];

      if (allowedParams.indexOf(searchByField) !== -1) {
        const searchByParam = `q[${searchByField}]`;

        const url = "/api/users";

        const params = {
          utf8: "%E2%9C%93",
          page,
          limit,
          [searchByParam]: searchByText
        };

        return this.GlazeClient.get(url, params);
      }
    },
    searchUsersByExactEmail (email) {
      return this.searchUsers("email_eq", email, 1, 1);
    },
    createUserWithoutPassword (email, channel, location) {
      const url = "/api/users";

      const params = {
        default_password_and_invite: true,
        user: {
          email
        }
      };

      if (channel) {
        params.user.created_channel = channel;
      }

      if (location && location.id) {
        params.user.created_store_id = location.id;
      }

      return this.GlazeClient.post(url, params);
    }
  }
};
export default mixin;
