import GlazeClient from "@framebridge/toolbox/apihelpers/glazeClient";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async create () {
      return undefined;
    },
    async read () {
      return undefined;
    },
    async update () {
      return undefined;
    },
    async delete ({ rootState }, payload) {
      const { orderNumber, lineItemId } = payload;

      const uri = `/api/orders/${orderNumber}/line_items/${lineItemId}`;
      const client = new GlazeClient(rootState.user.glazeToken);

      let response;

      try {
        response = await client.delete(uri);
      } catch (error) {
        console.error("Error deleting LineItem", { error });
      }

      if (response.ok) {
        return true;
      } else {
        return false;
      }
    }
  }
};
