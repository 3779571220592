import Vue from "vue";
import Vuex from "vuex";
import GlazeClient from "@framebridge/toolbox/apihelpers/glazeClient";
import upperFirst from "lodash/upperFirst";
// Enable vuex for global vue instance.
Vue.use(Vuex);

const set = key => (state, val) => {
  Vue.set(state, key, val);
};

const slugToName = function (modalSlug) {
  return modalSlug.replace(/-([a-z])/g, (matches) => matches[1].toUpperCase());
};

export default {
  namespaced: true,
  state: {
    includedAddOns: {},
    initialMatCaption: undefined,
    initialMouldingPlate: undefined,
    initialStoryPocket: undefined,
    initialMiniStoryPocket: undefined,
    products: [],
    booting: false,
    matCaptionApplied: null,
    mouldingPlateApplied: null,
    storyPocketApplied: null,
    miniStoryPocketApplied: null
  },
  getters: {
    isApplied: (state) => (slug) => {
      return state[`${slugToName(slug)}Applied`];
    },
    renderedPersonalizationApplied: (state) => () => {
      return Boolean(
        state.matCaptionApplied ||
        state.mouldingPlateApplied
      );
    }
  },
  mutations: {
    setInitialMatCaption: set("initialMatCaption"),
    setInitialMouldingPlate: set("initialMouldingPlate"),
    setInitialStoryPocket: set("initialStoryPocket"),
    setInitialMiniStoryPocket: set("initialMiniStoryPocket"),
    setIncludedAddOns: set("includedAddOns"),
    setProducts: set("products"),
    setBooting: set("booting"),
    setMatCaptionApplied: set("matCaptionApplied"),
    setMouldingPlateApplied: set("mouldingPlateApplied"),
    setStoryPocketApplied: set("storyPocketApplied"),
    setMiniStoryPocketApplied: set("miniStoryPocketApplied")
  },
  actions: {
    boot ({ commit, state }) {
      if (state.booting) {
        return Promise.resolve;
      }

      commit("setBooting", true);
      const client = new GlazeClient();
      return client
        .get("/api/add_on_definitions", {}, { rejectOnNotOk: true })
        .then(response => {
          const products = response.data.addOnDefinitions
            .filter(addOn => addOn.product && addOn.product.id)
            .map(each => {
              const product = {
                ...each.product,
                price: parseFloat(each.product.price)
              };

              // Clever?
              const includedAddOnsSlugs = Object.keys(state.includedAddOns);

              if (includedAddOnsSlugs.includes(product.slug)) {
                const { discountAmount } = state.includedAddOns[product.slug];
                // A primary indicator to determine if the product is required.
                product.includedInProduct = true;
                // Change the base price in the context of this product.
                product.price = product.price - parseFloat(discountAmount);

                commit(`set${upperFirst(slugToName(product.slug))}Applied`, true);
              }

              return product;
            });
          commit("setProducts", products);
          commit("setBooting", false);
        })
        .catch(() => {
          commit("setBooting", false);
        });
    }
  },
  modules: {}
};
