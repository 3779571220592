import Oops from "../../pages/shared/Oops.vue";
import RouteView from "../../pages/general/RouteView.vue";

function fallback (component) {
  return function (error) {
    if (error) {
      console.error(error);
    }
    return component;
  };
}

const routes = [
  {
    path: "*",
    name: "home",
    redirect: "/retail/find/order"
  },
  // a full page preview without any navigation or drawers
  {
    path: "/preview",
    component: () => import("../../pages/FramePreview.vue").catch(fallback(Oops))
  },
  {
    path: "/general",
    component: () => import("../../pages/general/GeneralPageWithNavigation.vue").catch(fallback(RouteView)),
    children: [
      // a placeholder route to link to for buttons or actions that do not yet
      // exist
      {
        path: "justkidding",
        name: "justKidding",
        component: () => import("../../pages/shared/JustKidding.vue").catch(fallback(Oops))
      },
      // the login page
      {
        path: "login",
        name: "login",
        component: () => import("../../pages/general/LogIn.vue").catch(fallback(Oops))
      }
    ]
  },
  // generic views that aren't application specific
  {
    path: "/general",
    component: () => import("../../pages/retail/FrameBuilderLayout.vue").catch(fallback(RouteView)),
    children: [
      // a working frame spec creator with limited options that updates glaze
      // frame spec
      {
        path: "framespec/new",
        name: "newFrameSpec",
        component: () => import("../../pages/retail/RetailFrameBuilder.vue").catch(fallback(Oops))
      },
      // a working frame editor with limited options that updates glaze frame
      // specs
      {
        path: "framespec/:frameSpecId",
        name: "editFrameSpec",
        component: () => import("../../pages/retail/RetailFrameBuilder.vue").catch(fallback(Oops))
      }
    ]
  },
  {
    path: "/receiving",
    name: "retailReceivingTool",
    component: () => import("../../pages/receiving/Receiving.vue").catch(fallback(Oops))
  },
  {
    path: "/retail",
    component: () => import("../../pages/retail/RetailLayout.vue").catch(fallback(RouteView)),
    children: [
      {
        path: "orders/:orderNumber",
        name: "retailOrder",
        component: () => import("../../pages/retail/RetailOrders.vue").catch(fallback(Oops))
      },
      {
        path: "sneak-peek/:frameSpecId",
        name: "sneakPeek",
        component: () => import("../../pages/retail/SneakPeek.vue").catch(fallback(Oops))
      },
      {
        path: "line-item/:orderNumber/:lineItemNumber/:frameSpecId",
        name: "lineItemSummary",
        component: () => import("../../pages/retail/LineItemSummary.vue").catch(fallback(Oops))
      },
      {
        path: "find/order",
        name: "retailFindOrder",
        component: () => import("../../pages/retail/RetailFindOrder.vue").catch(fallback(Oops))
      },
      {
        path: "artworks/:uploadGroupId/edit",
        name: "classifyArtworks",
        component: () => import("Editor/components/ClassifyArtworks.vue").catch(fallback(Oops))
      },
      {
        path: "artworks/customer",
        name: "customerArtwork",
        component: () => import("Editor/components/CustomerArtworkPicker.vue").catch(fallback(Oops))
      },
      {
        path: "cart/:orderNumber",
        name: "retailCart",
        component: () => import("../../pages/retail/RetailCart.vue").catch(fallback(Oops))
      },
      {
        path: "cart/:orderNumber/checkout/",
        component: RouteView,
        children: [
          {
            path: "shipping",
            name: "retailShipping",
            component: () => import("../../pages/retail/RetailCheckoutShipping.vue").catch(fallback(Oops))
          },
          {
            path: "delivery",
            name: "retailDelivery",
            component: () => import("../../pages/retail/RetailCheckoutDelivery.vue").catch(fallback(Oops))
          },
          {
            path: "payment",
            name: "retailPayment",
            component: () => import("../../pages/retail/RetailCheckoutPayment.vue").catch(fallback(Oops))
          },
          {
            path: "confirm",
            name: "retailConfirm",
            component: () => import("../../pages/retail/RetailCheckoutConfirm.vue").catch(fallback(Oops))
          },
          {
            path: "complete",
            name: "retailComplete",
            component: () => import("../../pages/retail/RetailCheckoutComplete.vue").catch(fallback(Oops))
          }
        ]
      }
    ]
  }
];

export default routes;
