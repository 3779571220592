import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import userModule from "./modules/user";
import globalSnackbarModule from "./modules/globalSnackbar";
import customerConsultation from "./modules/customerConsultation";
import retailLocationModule from "./modules/retailLocation";
import specialInstructionTypeModule from "./modules/specialInstructionType";
import CartResource from "./modules/resources/Cart";
import OrderResource from "./modules/resources/Order";
import LineItemResource from "./modules/resources/LineItem";
import frameBuilder from "Editor/store/frameBuilder";
import personalizationStore from "./modules/personalizationStore";
import state from "./state";
import getters from "./getters";
import mutations from "./mutations";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: [
    // only save these modules to local storage
    "user",
    "retailLocation",
    "customerConsultation"
  ]
});

const store = new Vuex.Store({
  state,
  getters,
  mutations,
  modules: {
    user: userModule,
    globalSnackbar: globalSnackbarModule,
    retailLocation: retailLocationModule,
    specialInstructionType: specialInstructionTypeModule,
    Cart: CartResource,
    Order: OrderResource,
    LineItem: LineItemResource,
    frameBuilder,
    customerConsultation,
    personalization: personalizationStore
  },
  plugins: [
    vuexLocal.plugin
  ]
});

export default store;
