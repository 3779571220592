/**
 * Retail Location Module
 *
 * Store the current selected retail location in vuex.
 */
const retailLocationModule = {
  namespaced: true,
  state: {
    location: null
  },
  getters: {
    // This object is immutable.
    location (state) {
      return state.location;
    },
    id (state) {
      return (state.location ? state.location.id : null);
    }
  },
  mutations: {
    setLocation (state, location) {
      state.location = Object.freeze(Object.assign({}, location));
    },
    clearLocation (state) {
      state.location = null;
    }
  }
};

export default retailLocationModule;
