import GlazeClient from "@framebridge/toolbox/apihelpers/glazeClient";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async create () {
      return undefined;
    },
    async read ({ rootState }, payload) {
      const { orderNumber } = payload;

      const uri = `/api/orders/${orderNumber}`;
      const client = new GlazeClient(rootState.user.glazeToken);

      let response;

      try {
        response = await client.get(uri);
      } catch (error) {
        console.error("Error reading Order", { error });
      }

      if (response.ok) {
        return response.data;
      } else {
        return false;
      }
    },
    async update () {
      return undefined;
    },
    async delete () {
      return undefined;
    },
    async applyDiscount () {
      return undefined;
    }
  }
};
