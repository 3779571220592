export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async current ({ rootGetters }) {
      const order = rootGetters["customerConsultation/order"];

      const payload = {
        orderNumber: order.number
      };

      const cart = await this.dispatch("Order/read", payload);

      return cart;
    }
  }
};
